import {
  GET_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT_MARK_AS_SEEN,
  REFRESH_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT_MARK_ALL_AS_SEEN,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  announcements: [],
  totalNotViewed: 0,
};

export default function Announcements(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload.records,
        totalNotViewed: action.payload.totalNotViewed,
      };
    case CREATE_ANNOUNCEMENT:
      return { ...state, totalNotViewed: state.totalNotViewed + 1 };
    case UPDATE_ANNOUNCEMENT_MARK_AS_SEEN:
      return {
        ...state,
        totalNotViewed: state.totalNotViewed === 0 ? 0 : state.totalNotViewed - 1,
      };
    case UPDATE_ANNOUNCEMENT_MARK_ALL_AS_SEEN:
      return {
        ...state,
        totalNotViewed: state.totalNotViewed === 0 ? 0 : state.totalNotViewed - 1,
      };
    case UPDATE_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload.records,
        totalNotViewed: action.payload.totalNotViewed,
      };
    case DELETE_ANNOUNCEMENTS:
      return { ...state, totalNotViewed: state.totalNotViewed > 0 ? state.totalNotViewed - 1 : 0 };
    case REFRESH_ANNOUNCEMENTS:
      return { ...state, announcements: action.payload.records, totalNotViewed: action.payload.totalNotViewed };
    default:
      return state;
  }
}
