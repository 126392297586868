import React from 'react';
import { Row } from 'antd';
// import { logoEsquerdaImg, logoCentroImg, logoDireitaImg } from '../../../assets/images';

export default function Header() {
  return (
    <div className="gx-ml-4 gx-mr-4">
      {/* <Row align="middle" justify="center">
        <Col sm={4}>
          <img style={{ maxHeight: 132 }} src={logoEsquerdaImg} alt="logo" />
        </Col>
        <Col align="center" sm={16}>
          <img src={logoCentroImg} alt="logo" />
        </Col>
        <Col sm={4}>
          <img style={{ maxHeight: 132 }} src={logoDireitaImg} alt="logo" />
        </Col>
      </Row> */}
      <Row justify="center" className="gx-mt-3">
        <h1 style={{ fontSize: 40, marginTop: 10, fontWeight: 'bold', color: '#00215F' }}>FICHA DE CADASTRO DE CLIENTES</h1>
      </Row>
    </div>
  );
}
