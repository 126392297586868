import React, { useState, useEffect } from 'react';
import { Modal, Button, Space, message, Row, Col, Switch, Input, Table, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeCopyMoveModal, refreshGed } from '../../redux/actions';
import { postMoveFiles, postCopyFiles, postMoveFolders, postCopyFolders, getSearchFolders } from '../../services';
import IntlMessages from '../../util/IntlMessages';
import useDebounce from '../../util/UseDebounce';

const { Search } = Input;

export function CopyMoveModal() {
  const [loading, setLoading] = useState(false);
  const [folderQuery, setFolderQuery] = useState('');
  const [result, setResult] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const debouncedFolderQuery = useDebounce(folderQuery, 500);
  const dispatch = useDispatch();
  const { copyMoveModalIsOpen, mode, objects } = useSelector(({ copyMoveModal }) => copyMoveModal);

  const closeModal = () => {
    dispatch(closeCopyMoveModal());
    setLoading(false);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setResult([]);
    setFolderQuery('');
  };

  const files = objects.filter((x) => x.isLeaf);
  const folders = objects.filter((x) => !x.isLeaf);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await getSearchFolders(debouncedFolderQuery);
        setResult(data);
      } catch {}
      setLoading(false);
    };
    // if (!!debouncedFolderQuery) {
    getData();
    // }
  }, [copyMoveModalIsOpen, debouncedFolderQuery]);

  const columns = [
    {
      title: 'Nome da pasta',
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: 'Caminho',
      dataIndex: 'path',
      key: 'path',
      width: 'auto',
      render: (path) => {
        return <small>{`${path.map((x) => x.title).join('/')}`}</small>;
      },
    },
  ];

  const rowSelection = {
    type: 'radio',
    selectedRowKeys: selectedRows.map((x) => x._id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const onConfirm = async () => {
    setLoading(true);
    try {
      const destinationFolder = selectedRows[0];
      if (folders.length > 0) {
        const params = { folder: destinationFolder._id, folders: folders.map((x) => x._id) };
        if (mode === 'MOVE') {
          message.info(`Movendo ${folders.length} pastas. Aguarde...`);
          await postMoveFolders(params);
          message.success(`${folders.length} pastas movidas com sucesso!`);
        } else {
          message.info(`Copiando ${folders.length} pastas. Aguarde...`);
          await postCopyFolders(params);
          message.success(`${folders.length} pastas copiadas com sucesso!`);
        }
      }
      if (files.length > 0) {
        const params = { folder: destinationFolder._id, files: files.map((x) => x._id) };
        if (mode === 'MOVE') {
          message.info(`Movendo ${files.length} arquivos. Aguarde...`);
          await postMoveFiles(params);
          message.success(`${files.length} arquivos movidas com sucesso!`);
        } else {
          message.info(`Copiando ${files.length} arquivos. Aguarde...`);
          await postCopyFiles(params);
          message.success(`${files.length} arquivos copiadas com sucesso!`);
        }
      }
      closeModal();
      dispatch(refreshGed());
    } catch {}
    setLoading(false);
  };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRows(selectedRows);
  //   },
  //   onSelect: (selectedRows) => {
  //     setSelectedRows(selectedRows);
  //   },
  //   onSelectAll: (selectedRows) => {
  //     setSelectedRows(selectedRows);
  //   },
  // };

  const [checkStrictly, setCheckStrictly] = useState(true);

  return (
    <Modal
      title={'Buscar pasta de destino'}
      visible={copyMoveModalIsOpen}
      onCancel={closeModal}
      width={'90vw'}
      style={{ maxHeight: '100vh' }}
      bodyStyle={{ height: '100%', maxHeight: '550px', overflowY: 'auto' }}
      forceRender
      centered
      footer={
        <Space>
          <Button disabled={loading} type="secondary" onClick={() => closeModal()}>
            <IntlMessages id="app.general.cancel_btn" />
          </Button>
          <Popconfirm
            onConfirm={onConfirm}
            title={
              <>
                Tem certeza? <br /> Esta operação não pode ser desfeita.
              </>
            }
          >
            <Button disabled={loading || selectedRows.length === 0} loading={loading} type="primary">
              {mode === 'COPY' ? 'Copiar' : 'Mover'}
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <Row>
        <Col lg={24}>
          <Search allowClear autoFocus value={folderQuery} onChange={({ target }) => setFolderQuery(target.value)} placeholder="Digite o nome da pasta..." loading={loading} />
        </Col>
        <Col lg={24}>
          <Table
            columns={columns}
            loading={loading}
            rowSelection={{
              ...rowSelection,
              type: 'radio',
              checkStrictly,
            }}
            dataSource={result}
            size="small"
            rowKey={'_id'}
          />
        </Col>
      </Row>
    </Modal>
  );
}
