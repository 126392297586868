import React, { useState, useEffect } from 'react';
import { Button, Modal, Empty, Typography, Spin } from 'antd';

import { BellOutlined } from '@ant-design/icons';
import { putAnnouncementAsAllSeen, putAnnouncementAsSeen } from '../../services';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getAnnouncements, putAnnouncementMarkAllAsSeen, putAnnouncementMarkAsSeen, refreshAnnouncements } from '../../redux/actions';

const { Text } = Typography;

const announcement_counter = {
  position: 'absolute',
  right: '-6px',
  top: '-9px',
  border: '1px solid #fb6422',
  borderRadius: '50%',
  fontSize: '13px',
  padding: '3px 3px',
  color: '#fff',
  backgroundColor: '#fb6422',
  textAlign: 'center',
};
const divisor = {
  borderBottom: '1px solid #e4e4e4',
};
const footer_info = {
  display: 'flex',
  justifyContent: 'space-between',
};
const message_date = {
  paddingBottom: '5px',
  textAlign: 'right',
};
const message_sender = {
  paddingBottom: '5px',
  textAlign: 'left',
};
const message_title = {
  fontWeight: '600',
  color: '#545454',
};
const message_description = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  // WebkitLineClamp: 4,
  // overflow: 'hidden',
  textOverflow: 'ellipsis',
};
const message_container = {
  position: 'relative',
  // maxHeight: '120px',
  minHeight: '85px',
  marginBottom: '25px',
  padding: 5,
  borderRadius: 8,
  cursor: 'pointer',
};
const message_new = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '10px',
  height: '10px',
  backgroundColor: '#fb6422',
  borderRadius: '100%',
};

export default function Announcement() {
  const defaultPagination = { current: 1, pageSize: 20 };
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const { totalNotViewed, announcements } = useSelector(({ announcements }) => announcements);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshAnnouncements(defaultPagination));
  }, [dispatch]);

  // const getData = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     // const { data } = await getAnnouncementsByUser(defaultPagination);
  //     // dispatch(getAnnouncements(defaultPagination));
  //     // setAnnouncement(data.records);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   getData();
  // }, [getData]);

  const onMarkAsSeen = async (id) => {
    return updateNewAnnouncement(id);
  };

  const updateNewAnnouncement = async (id) => {
    try {
      setLoading(true);
      await putAnnouncementAsSeen(id);
      dispatch(putAnnouncementMarkAsSeen());
      dispatch(refreshAnnouncements(defaultPagination));
    } catch {
      alert('Não foi possível atualizar o status da mensagem.');
    } finally {
      setLoading(false);
    }
  };

  const markAllAsSeen = async () => {
    try {
      setLoading(true);
      await putAnnouncementAsAllSeen();
      dispatch(refreshAnnouncements(defaultPagination));
    } catch {
      alert('Não foi possível atualizar o status da mensagem.');
    } finally {
      setLoading(false);
    }
  };

  const [modalStyle, setModalStyle] = useState({
    position: 'absolute',
    top: '60px',
    right: '130px',
  });

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        setModalStyle({
          ...modalStyle,
          top: '60px',
          right: '10px',
        });
      } else {
        setModalStyle({
          ...modalStyle,
          top: '60px',
          right: '130px',
          transform: 'none',
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [modalStyle]);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const title = (
    <div style={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
      <Text>Notificações e Comunicados</Text>
    </div>
  );

  const content = (
    <div>
      <div
        style={{
          maxHeight: '500px',
          width: '100%',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            maxHeight: '500px',
            overflow: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent transparent',
          }}
        >
          <style>
            {`

            .message_onhover:hover {
              background: #f2f2f2;
            }
            ::-webkit-scrollbar {
              width: 6px;
            }

            ::-webkit-scrollbar-track {
              background: transparent;
            }

            ::-webkit-scrollbar-thumb {
              background-color: rgba(0, 0, 0, 0.2);
              border-radius: 3px;
            }
          `}
          </style>
          {announcements ? (
            announcements?.map((announce, index) => (
              <div key={index} style={message_container} className="message_onhover" onClick={() => onMarkAsSeen(announce._id)}>
                <h5 style={message_title}>{announce.subject}</h5>
                <p style={message_description}>{announce.description}</p>
                <div style={footer_info}>
                  <span style={message_date}>{announce.senderId.firstName}</span>
                  <span style={message_sender}>{moment(announce.createdAt).format('DD/MM/YYYY HH:mm')}</span>
                </div>
                {announce.viewedAt === null && <div style={message_new}></div>}

                <div style={divisor}></div>
              </div>
            ))
          ) : (
            <div>
              <Empty description={loading ? 'Carregando' : 'Nenhum comunicado encontrado'} />
            </div>
          )}
        </div>
      </div>
      <Button type="link" block style={{ marginBottom: 0 }} onClick={() => markAllAsSeen()}>
        Marcar todos como lido
      </Button>
    </div>
  );

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <BellOutlined onClick={handleOpenModal} style={{ fontSize: '25px' }} />
        {totalNotViewed > 0 && <div style={announcement_counter}>{totalNotViewed}</div>}
        {/* {totalNotViewed > 0 && <div style={announcement_counter}>{totalNotViewed}</div>} */}
        <Modal title={title} visible={modalVisible} onCancel={handleCloseModal} footer={false} getContainer={false} style={modalStyle}>
          {content}
        </Modal>
      </div>
    </div>
  );
}
