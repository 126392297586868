import { Col, Divider, Row } from 'antd';
import React from 'react';

export const Privacy = () => {
  return (
    <>
      <div className="gx-ml-4 gx-mr-4">
        <Row justify="center" className="gx-mt-3">
          <h1 style={{ fontSize: 40, marginTop: 10, fontWeight: 'bold', color: '#00215F' }}>POLITICA DE PRIVACIDADE</h1>
        </Row>
      </div>
      <Divider />
      <Row justify="center" className="gx-mt-5" style={{ maxWidth: '100vw' }}>
        <Col xs={22} md={16}>
          <div className="gx-flex gx-gap-3" style={{ color: '#747474', lineHeight: '2rem' }}>
            <p>
              A sua privacidade é importante para nós. É política Ruff respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site{' '}
              <a href="https://www.ruff.com.br">www.ruff.com.br</a>, e outros sites que possuímos e operamos.
            </p>
            <p>
              Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento.
              Também informamos por que estamos coletando e como será usado.
            </p>
            <p>
              Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, os protegemos dentro de meios comercialmente aceitáveis ​​para
              evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
            </p>
            <p>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
            <p>
              O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar
              responsabilidade por suas respectivas políticas de privacidade.
            </p>
            <p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>
            <p>
              O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados
              do usuário e informações pessoais, entre em contato conosco.
            </p>
            <ul>
              <li aria-level="1">
                O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um
                determinado anúncio é exibido para você.
              </li>
              <li aria-level="1">Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.</li>
              <li aria-level="1">
                Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados ​​por
                este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que
                possam ser do seu interesse.
              </li>
              <li aria-level="1">
                Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos
                parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma
                compra.
              </li>
            </ul>
            <h2 style={{ color: `#00215F` }}>Compromisso do usuário</h2>
            <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o www.ruff.com.br oferece no site e com caráter enunciativo, mas não limitativo:</p>
            <p style={{ paddingLeft: 40 }}>A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</p>
            <p style={{ paddingLeft: 40 }}>
              B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, bet365 brasil ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos
              humanos;
            </p>
            <p style={{ paddingLeft: 40 }}>
              C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do <a href="https://www.ruff.com.br">www.ruff.com.br</a>, de seus fornecedores ou terceiros, para introduzir ou
              disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
            </p>
            <h2 style={{ color: `#00215F` }}>Mais informações</h2>
            <p>
              Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso
              interaja com um dos recursos que você usa em nosso site.
            </p>
            <p>
              Dúvidas entrar em contato via:{' '}
              <a>
                <span>dpo@ruff.com.br</span>
              </a>
            </p>
            <p>Esta política é efetiva a partir de 5 de Abril de 2023.</p>
          </div>
        </Col>
      </Row>
    </>
  );
};
