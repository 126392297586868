import {
  GET_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENT,
  REFRESH_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT_MARK_AS_SEEN,
  UPDATE_ANNOUNCEMENT_MARK_ALL_AS_SEEN,
} from '../../constants/ActionTypes';

export const getAnnouncements = (params) => {
  return {
    type: GET_ANNOUNCEMENTS,
    payload: params,
  };
};

export const postAnnouncementAction = (announcements) => {
  return {
    type: CREATE_ANNOUNCEMENT,
    payload: announcements,
  };
};

export const putAnnouncement = (announcements) => {
  return {
    type: UPDATE_ANNOUNCEMENTS,
    payload: announcements,
  };
};

export const putAnnouncementMarkAsSeen = (announcements) => {
  return {
    type: UPDATE_ANNOUNCEMENT_MARK_AS_SEEN,
    payload: announcements,
  };
};

export const putAnnouncementMarkAllAsSeen = (announcements) => {
  return {
    type: UPDATE_ANNOUNCEMENT_MARK_ALL_AS_SEEN,
    payload: announcements,
  };
};

export const refreshAnnouncements = (announcements) => {
  return {
    type: REFRESH_ANNOUNCEMENTS,
    payload: announcements,
  };
};
