import api from './ApiService';

export async function login(email, password) {
  const params = { email, password };
  const { data } = await api.post('auth-login', params);
  return data;
}

export async function forgotPasswordRequest(email) {
  const params = { email };
  const { data } = await api.post('auth-request-password-recovery', params);
  return data;
}

export async function recoveryPasswordRequest(params) {
  const { data } = await api.post('auth-submit-password-recovery', params);
  return data;
}

export async function resendConfirmationEmail(email) {
  const params = { email };
  const { data } = await api.post('auth-resend-confirmation-email', params);
  return data;
}

export async function confirmEmail(confirmationEmailToken) {
  await api.post(`auth-confirm-email/${confirmationEmailToken}`);
  return true;
}

export async function logout() {
  localStorage.removeItem('state');
  sessionStorage.removeItem('state');
  window.location.href = '/login';
  return true;
}
