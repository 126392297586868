import axios from 'axios';
import { message } from 'antd';
import { defaultLocale } from '../config';
import { refreshToken } from '../redux/actions';
import store from '../redux/store';

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = 'https://ged-ruff-api.devplace.com.br/api/v1';
const instance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(handleRequestConfig, Promise.reject);
instance.interceptors.response.use(handleResponseSuccess, handleResponseError);

function handleRequestConfig(config) {
  config.headers.locale = getLocale();
  const token = getToken();
  if (!!token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

function handleResponseError(error) {
  try {
    if (!!error.response) {
      const { data, status } = error.response;
      if (status === 422) {
        data.data.forEach((validationErr) => message.error(validationErr.msg));
      } else if (status === 401) {
        alert(data.message);
        window.location = '/logout';
      } else {
        message.error(data.message);
      }
    } else {
      message.error('Falha de comunicação. Não foi possível localizar o servidor.', 5);
      message.info('Verifique sua conexão ou tente novamente em alguns minutos.', 5);
    }
  } catch {}
  return Promise.reject(error);
}

async function handleResponseSuccess(response) {
  try {
    const { headers } = response;
    if (headers['refresh-token']) {
      store.dispatch(refreshToken(headers['refresh-token']));
    }
  } catch {}
  return response;
}

function getToken() {
  let token = null;
  try {
    token = store.getState().auth.token;
  } catch (e) {}
  return token;
}

function getLocale() {
  try {
    const cookiesAllow = localStorage.getItem('@ruff:cookies');
    let locale = JSON.parse(cookiesAllow === 'true' ? localStorage.getItem('state') : sessionStorage.getItem('state')).profile.locale;
    if (!locale) return defaultLocale.locale;
    return locale;
  } catch (e) {
    return defaultLocale.locale;
  }
}

export default instance;
