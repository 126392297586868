import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Profile from './Profile';
import City from './City';
import Ged from './Ged';
import Comments from './Comments';
import Timeline from './Timeline';
import UploadVersionModal from './UploadVersionModal';
import GedPermissions from './GedPermissions';
import Templates from './Templates';
import FileRevision from './FileRevision';
import CopyMoveModal from './CopyMoveModal';
import Customer from './Customer';
import Tree from './Tree';
import Announcements from './Announcements';

const createRootReducer = (history) =>
  combineReducers({
    settings: Settings,
    auth: Auth,
    common: Common,
    profile: Profile,
    city: City,
    ged: Ged,
    comments: Comments,
    announcements: Announcements,
    timeline: Timeline,
    router: connectRouter(history),
    uploadVersionModal: UploadVersionModal,
    gedPermissions: GedPermissions,
    templates: Templates,
    fileRevision: FileRevision,
    customer: Customer,
    copyMoveModal: CopyMoveModal,
    tree: Tree,
  });

export default createRootReducer;
