export const loadState = () => {
  try {
    const cookiesAllow = localStorage.getItem('@ruff:cookies');

    let serializedState;

    if (cookiesAllow === 'false') {
      serializedState = sessionStorage.getItem('state');
    } else {
      serializedState = localStorage.getItem('state');
    }
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const cookiesAllow = localStorage.getItem('@ruff:cookies');

    const serializedState = JSON.stringify(state);
    if (cookiesAllow === 'false') {
      sessionStorage.setItem('state', serializedState);
    } else {
      localStorage.setItem('state', serializedState);
    }
  } catch {
    // ignore write errors
  }
};
