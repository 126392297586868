import { SIGNIN_USER_SUCCESS, REFRESH_TOKEN, SUBTRACT_TIME } from '../../constants/ActionTypes';
import moment from 'moment';
import * as dayjs from 'dayjs';
const INIT_STATE = {
  token: null,
  type: 'Bearer',
  expiresIn: null,
  expiresInMs: null,
  logoutTime: dayjs(),
};

export default function Auth(state = INIT_STATE, action) {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS:
      return { ...state, ...action.payload };
    case REFRESH_TOKEN:
      return { ...state, token: action.payload };
    case SUBTRACT_TIME:
      return { ...state, logoutTime: action.payload };
    default:
      return state;
  }
}
