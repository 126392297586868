import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const cookieskey = `@ruff:cookies`;

export const CookiesAlertModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const allowed = window.localStorage.getItem(cookieskey);

    if (allowed === null) {
      setModalIsOpen(true);
    }
  }, []);

  return (
    <Modal
      visible={modalIsOpen}
      okText="Aceitar"
      cancelText="Negar"
      onOk={() => {
        window.localStorage.setItem(cookieskey, 'true');
        setModalIsOpen(false);
      }}
      onCancel={() => {
        window.localStorage.setItem(cookieskey, 'false');
        setModalIsOpen(false);
      }}
    >
      <p className="gx-mt-4">
        Utilizamos cookies para aprimorar sua experiência de navegação, exibir anúncios ou conteúdo personalizado e analisar nosso tráfego. Ao clicar em "Aceitar", você concorda com nosso uso de
        cookies.{' '}
      </p>
      <Link to={`/politica-de-privacidade`} className="gx-mt-3">
        Politica de Privacidade
      </Link>
    </Modal>
  );
};
