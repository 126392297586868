import React from 'react';
import { Menu, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomScrollbars from '../../util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import { THEME_TYPE_LITE, NAV_STYLE_MINI_SIDEBAR } from '../../constants';
import IntlMessages from '../../util/IntlMessages';
import routes from '../../routes';
import { Timer } from '../../components/Timer';
import Announcement from '../../components/Announcements';
import UserInfo from '../../components/UserInfo';

const { SubMenu, ItemGroup, Item } = Menu;
const { REACT_APP_ENV_COLOR = 'green', REACT_APP_VERSION = 'v1.0.0', REACT_APP_ENV_TITLE = 'Ambiente de desenvolvimento' } = process.env;
export default function SidebarContent() {
  const { navStyle, themeType, pathname, width } = useSelector(({ settings }) => settings);

  const { role } = useSelector(({ profile }) => profile);
  const { permissions = [] } = role;

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const getFilteredRoutes = () => {
    let filteredGroups = [];
    //Verifica se ao menos um dos menus possui permissão. Se nenhum menu tiver permissão, nem mostra categoria
    routes
      .filter((group) => !group.permission || permissions.some((x) => x.slug === group.permission))
      .forEach((group) => {
        let filteredMenus = [];
        group.menus.forEach((menu) => {
          if (!menu.permission || permissions.some((x) => x.slug === menu.permission)) {
            let filteredChilds = [];
            menu.childs.forEach((child) => {
              if (!child.permission || permissions.some((x) => x.slug === child.permission)) {
                filteredChilds.push(child);
              }
            });
            if (filteredChilds.length > 0) {
              filteredMenus.push({ ...menu, childs: filteredChilds });
            } else if (menu.childs.length === 0) {
              //Menu unitário
              filteredMenus.push(menu);
            }
          }
        });
        if (filteredMenus.length > 0) {
          filteredGroups.push({ ...group, menus: filteredMenus });
        }
      });
    return filteredGroups;
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className="gx-sidebar-notifications">
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {width < 700 && (
            <ul className="gx-d-flex gx-flex-column gx-header-notifications gx-ml-auto">
              <li className="gx-user-nav gx-my-2 gx-text-center">
                <Tag color={REACT_APP_ENV_COLOR}>{`${REACT_APP_ENV_TITLE} (${REACT_APP_VERSION})`}</Tag>
              </li>
              <div className="gx-header-notifications" style={{ gap: '20px', width: '100%', justifyContent: 'space-between' }}>
                <li className="gx-user-nav ">
                  <Announcement />
                </li>
                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </div>
              <li className="gx-user-nav gx-mt-2 gx-mr-4">
                <Timer></Timer>
              </li>
            </ul>
          )}
          <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
            {getFilteredRoutes().map((group) => (
              <ItemGroup key={group.group_key} className="gx-menu-group" title={<IntlMessages id={group.language_key} />}>
                {group.menus.map((menu) =>
                  menu.childs.length > 0 ? (
                    <SubMenu key={menu.menu_key} icon={menu.icon} title={<IntlMessages id={menu.language_key} />}>
                      {menu.childs
                        .filter((x) => !x.permission || permissions.some((y) => y.slug === x.permission))
                        .map((sub_menu) => (
                          <Item key={sub_menu.sub_menu_key}>
                            <Link to={sub_menu.path}>
                              {sub_menu.icon} <IntlMessages id={sub_menu.language_key} />
                            </Link>
                          </Item>
                        ))}
                    </SubMenu>
                  ) : (
                    <Item key={menu.menu_key} title={<IntlMessages id={menu.language_key} />}>
                      <Link to={menu.path}>
                        {menu.icon} {navStyle !== NAV_STYLE_MINI_SIDEBAR && <IntlMessages id={menu.language_key} />}
                      </Link>
                    </Item>
                  )
                )}
              </ItemGroup>
            ))}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
}
