import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subtractTime, userSignOut } from '../../redux/actions';
import * as dayjs from 'dayjs';
import { loadState } from '../../redux/store/localStorage';
export const Timer = () => {
  const dispatch = useDispatch();
  const [timeRemaining, setTimeRemaining] = useState('00:00');
  useEffect(() => {
    try {
      const state = loadState();
      if (!state) {
        dispatch(userSignOut());
      }

      const signInTime = state.auth.logoutTime;

      const logoutTime = dayjs(signInTime).add(1, 'hour');
      const interval = setInterval(() => {
        if (dayjs().isAfter(logoutTime)) {
          dispatch(userSignOut());
        }
        const remainingSeconds = logoutTime.diff(dayjs(), 'seconds');
        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = remainingSeconds % 60;
        setTimeRemaining(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      }, 1000);

      return () => clearInterval(interval);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  return (
    <div className="gx-flex gx-align-items-center gx-flex-column gx-pt-2">
      <p className="gx-text-grey " style={{ fontSize: '0.7rem', lineHeight: '1px' }}>
        A sessão expira em:
      </p>
      <strong className="gx-text-grey " style={{ fontSize: '0.7rem' }}>
        {timeRemaining}
      </strong>
    </div>
  );
};
