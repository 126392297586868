import { SIGNIN_USER, SIGNIN_USER_SUCCESS, SIGNOUT_USER, FORGOT_PASSWORD, RECOVERY_PASSWORD, REFRESH_TOKEN, SUBTRACT_TIME } from '../../constants/ActionTypes';

export const userSignIn = (loginData) => {
  return {
    type: SIGNIN_USER,
    payload: loginData,
  };
};

export const userForgotPassword = (params) => {
  return {
    type: FORGOT_PASSWORD,
    payload: params,
  };
};

export const userRecoveryPassword = (params) => {
  return {
    type: RECOVERY_PASSWORD,
    payload: params,
  };
};

export const userSignInSuccess = (authData) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authData,
  };
};

export const refreshToken = (newToken) => {
  return {
    type: REFRESH_TOKEN,
    payload: newToken,
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};

export const subtractTime = (newTime) => {
  return {
    type: SUBTRACT_TIME,
    payload: newTime,
  };
};
