import api from './ApiService';
import qs from 'query-string';

export async function getAnnouncements(query) {
  const { data } = await api.get(`announcements?${qs.stringify(query)}`);
  return data;
}

export async function getAnnouncementsByUser(query) {
  // const { data } = await api.get(`announcements/user?${qs.stringify(query)}`);
  return null;
}

export async function getAnnouncement(id) {
  const { data } = await api.get(`announcement/${id}`);
  return data;
}

export async function postAnnouncement(params) {
  const { data } = await api.post(`announcement`, params);
  return data;
}

export async function putAnnouncementAsSeen(id) {
  const { data } = await api.put(`announcement/mark-as-seen/${id}`);
  return data;
}

export async function putAnnouncementAsAllSeen() {
  const { data } = await api.put(`announcement/mark-all-as-seen`);
  return data;
}
