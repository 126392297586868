import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import NotFoundPage from './Errors/404';

const ImportingComponent = () => {
  useEffect(() => {
    Nprogress.start();
    return () => {
      Nprogress.done();
    };
  }, []);
  return null;
};

const Dashboard = lazy(() => import('./Dashboard'));
const Announcement = lazy(() => import('./Announcement'));
const Users = lazy(() => import('./Users'));
const UserForm = lazy(() => import('./Users/Form'));
const UserShow = lazy(() => import('./Users/Show'));
const Roles = lazy(() => import('./Roles'));
const RolesForm = lazy(() => import('./Roles/Form'));
const RolesShow = lazy(() => import('./Roles/Show'));
const Explorer = lazy(() => import('./Explorer'));
const TreeView = lazy(() => import('./TreeView'));
const FileShow = lazy(() => import('./File/Show'));
const Tickets = lazy(() => import('./Tickets'));
const TicketsForm = lazy(() => import('./Tickets/Form'));
const TicketsShow = lazy(() => import('./Tickets/Show'));
const Groups = lazy(() => import('./Groups'));
const GroupsForm = lazy(() => import('./Groups/Form'));
const GroupsShow = lazy(() => import('./Groups/Show'));
const Templates = lazy(() => import('./Templates'));
const TemplatesForm = lazy(() => import('./Templates/Form'));
const TemplatesShow = lazy(() => import('./Templates/Show'));
const Trash = lazy(() => import('./Trash'));
const FileRevisions = lazy(() => import('./FileRevisions'));
const FileRevisionsShow = lazy(() => import('./FileRevisions/Show'));

export default function App({ match }) {
  return (
    <div className="gx-main-content-wrapper">
      <Suspense fallback={<ImportingComponent />}>
        <Switch>
          <Route path={`${match.url}dashboard`} component={Dashboard} />
          <Route path={`${match.url}announcement`} component={Announcement} />
          <Route path={`${match.url}users/create`} component={UserForm} />
          <Route path={`${match.url}users/:id/edit`} component={UserForm} />
          <Route path={`${match.url}users/:id/clone`} component={UserForm} />
          <Route path={`${match.url}users/:id`} component={UserShow} />
          <Route path={`${match.url}users`} component={Users} />
          <Route path={`${match.url}roles/create`} component={RolesForm} />
          <Route path={`${match.url}roles/:id/edit`} component={RolesForm} />
          <Route path={`${match.url}roles/:id`} component={RolesShow} />
          <Route path={`${match.url}roles`} component={Roles} />
          <Route path={`${match.url}explorer`} component={Explorer} />
          <Route path={`${match.url}tree`} component={TreeView} />
          <Route path={`${match.url}file/:id`} component={FileShow} />
          <Route path={`${match.url}tickets/create`} component={TicketsForm} />
          <Route path={`${match.url}tickets/:id`} component={TicketsShow} />
          <Route path={`${match.url}tickets`} component={Tickets} />
          <Route path={`${match.url}groups/create`} component={GroupsForm} />
          <Route path={`${match.url}groups/:id/edit`} component={GroupsForm} />
          <Route path={`${match.url}groups/:id`} component={GroupsShow} />
          <Route path={`${match.url}groups`} component={Groups} />
          <Route path={`${match.url}templates/create`} component={TemplatesForm} />
          <Route path={`${match.url}templates/:id/edit`} component={TemplatesForm} />
          <Route path={`${match.url}templates/:id`} component={TemplatesShow} />
          <Route path={`${match.url}templates`} component={Templates} />
          <Route path={`${match.url}trash`} component={Trash} />
          <Route path={`${match.url}file-revisions/:id`} component={FileRevisionsShow} />
          <Route path={`${match.url}file-revisions`} component={FileRevisions} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </div>
  );
}
