import React from 'react';
import { Row, Col } from 'antd';
import { logoDireitaImg, logoSigo } from '../../../assets/images';

export default function Header() {
  return (
    <div className="gx-ml-4 gx-mr-4">
      <Row align="middle" justify="center">
        <Col sm={4}>
          <img style={{ maxHeight: 132 }} src={logoSigo} alt="logo" />
        </Col>
        <Col align="center" sm={16}>
          <h1 style={{ color: '#F68628' }}>Sempre à frente!</h1>
        </Col>
        <Col sm={4}>
          <img style={{ maxHeight: 132 }} src={logoDireitaImg} alt="logo" />
        </Col>
      </Row>
      <Row justify="center" className="gx-mt-3">
        <h1>Ficha Cadastral - SIGO MG Transportador Revendedor Retalhista Ltda.</h1>
      </Row>
    </div>
  );
}
