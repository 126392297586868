import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_ANNOUNCEMENTS, CREATE_ANNOUNCEMENT, REFRESH_ANNOUNCEMENTS } from '../../constants/ActionTypes';
import { getAnnouncementsByUser, postAnnouncement } from '../../services';
import { putAnnouncement } from '../actions';

// Saga para buscar anúncios
function* getAnnouncementsSaga(action) {
  try {
    const { data } = yield call(getAnnouncementsByUser, action.payload);
    yield put(putAnnouncement(data));
  } catch (error) {
  }
}

// Saga para criar um novo anúncio
function* createAnnouncementSaga(action) {
  try {
    yield call(postAnnouncement, action.payload);
  } catch (error) {
  }
}

function* refreshAnnouncementsSaga(defaultPagination) {
  try {
    const { data } = yield call(getAnnouncementsByUser, defaultPagination);
    yield put(putAnnouncement(data));
  } catch (error) {
    // Lide com erros, se necessário
  }
}


// Função de raiz da saga que combina todas as sagas
export default function* announcementSaga() {
  yield takeLatest(GET_ANNOUNCEMENTS, getAnnouncementsSaga); // Intercepta a ação GET_ANNOUNCEMENTS e chama a saga getAnnouncementsSaga
  yield takeLatest(CREATE_ANNOUNCEMENT, createAnnouncementSaga); // Intercepta a ação CREATE_ANNOUNCEMENT e chama a saga createAnnouncementSaga
  yield takeLatest(REFRESH_ANNOUNCEMENTS, refreshAnnouncementsSaga);
}
