// Customizer consts
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const SWITCH_LANGUAGE_AFTER_LOGIN = 'SWITCH_LANGUAGE_AFTER_LOGIN';

//Common consts
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

//Auth consts
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SUBTRACT_TIME = 'SUBTRACT_TIME';

//Profile consts
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL';

//City consts
export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const GET_ALL_CITIES_SUCCESS = 'GET_ALL_CITIES_SUCCESS';
export const GET_ALL_CITIES_ERROR = 'GET_ALL_CITIES_ERROR';

//GED consts
export const GET_ROOT = 'GET_ROOT';
export const GET_ROOT_SUCCESS = 'GET_ROOT_SUCCESS';
export const GET_ROOT_ERROR = 'GET_ROOT_ERROR';
export const GET_FOLDER = 'GET_FOLDER';
export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS';
export const GET_FOLDER_ERROR = 'GET_FOLDER_ERROR';
export const REFRESH_GED = 'REFRESH_GED';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';
export const UPDATE_FOLDER_ERROR = 'UPDATE_FOLDER_ERROR';
export const GET_FILE = 'GET_FILE';
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
export const GET_FILE_ERROR = 'GET_FILE_ERROR';
export const OPEN_FILE_PREVIEW_DRAWER = 'OPEN_FILE_PREVIEW_DRAWER';
export const CLOSE_FILE_PREVIEW_DRAWER = 'CLOSE_FILE_PREVIEW_DRAWER';
export const OPEN_FILE_PREVIEW_MODAL = 'OPEN_FILE_PREVIEW_MODAL';
export const CLOSE_FILE_PREVIEW_MODAL = 'CLOSE_FILE_PREVIEW_MODAL';
export const OPEN_FOLDER_MODAL = 'OPEN_FOLDER_MODAL';
export const CLOSE_FOLDER_MODAL = 'CLOSE_FOLDER_MODAL';
export const OPEN_FILE_MODAL = 'OPEN_FILE_MODAL';
export const CLOSE_FILE_MODAL = 'CLOSE_FILE_MODAL';
export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_ERROR = 'UPDATE_FILE_ERROR';
export const SET_UPLOADING = 'SET_UPLOADING';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_ERROR = 'DELETE_FOLDER_ERROR';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILES = 'DELETE_FILES';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

//Comments consts
export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR';

//Timeline consts
export const GET_TIMELINE = 'GET_TIMELINE';
export const GET_TIMELINE_SUCCESS = 'GET_TIMELINE_SUCCESS';
export const GET_TIMELINE_ERROR = 'GET_TIMELINE_ERROR';

//Upload version modal
export const TOGGLE_UPLOAD_VERSION_MODAL = 'TOGGLE_UPLOAD_VERSION_MODAL';

//Ged permissions modal
export const OPEN_GED_PERMISSIONS_MODAL = 'OPEN_GED_PERMISSIONS_MODAL';
export const CLOSE_GED_PERMISSIONS_MODAL = 'CLOSE_GED_PERMISSIONS_MODAL';

//Template consts
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES';
export const GET_ALL_TEMPLATES_SUCCESS = 'GET_ALL_TEMPLATES_SUCCESS';
export const GET_ALL_TEMPLATES_ERROR = 'GET_ALL_TEMPLATES_ERROR';

//File revision
export const OPEN_NEW_FILE_REVISION_MODAL = 'OPEN_NEW_FILE_REVISION_MODAL';
export const CLOSE_NEW_FILE_REVISION_MODAL = 'CLOSE_NEW_FILE_REVISION_MODAL';
export const OPEN_FILE_REVISION_MODAL = 'OPEN_FILE_REVISION_MODAL';
export const CLOSE_FILE_REVISION_MODAL = 'CLOSE_FILE_REVISION_MODAL';
export const GET_REVISORS = 'GET_REVISORS';
export const GET_REVISORS_SUCCESS = 'GET_REVISORS_SUCCESS';
export const GET_REVISORS_ERROR = 'GET_REVISORS_ERROR';

//Customer consts
export const POST_CUSTOMER = 'POST_CUSTOMER';
export const POST_CUSTOMER_SUCCESS = 'POST_CUSTOMER_SUCCESS';
export const POST_CUSTOMER_ERROR = 'POST_CUSTOMER_ERROR';

//Move/Copy operations
export const OPEN_COPY_MOVE_MODAL = 'OPEN_COPY_MOVE_MODAL';
export const CLOSE_COPY_MOVE_MODAL = 'CLOSE_COPY_MOVE_MODAL';

//Tree view
export const SET_TREE_DATA = 'SET_TREE_DATA';
export const SET_TREE_LOADED = 'SET_TREE_LOADED';
export const SET_TREE_EXPANDED = 'SET_TREE_EXPANDED';
export const SET_TREE_CHECKED = 'SET_TREE_CHECKED';
export const SET_TREE_SELECTED = 'SET_TREE_SELECTED';

//ANNOUNCEMENTS
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENTS = 'DELETE_ANNOUNCEMENTS';
export const UPDATE_ANNOUNCEMENT_MARK_AS_SEEN = 'UPDATE_ANNOUNCEMENT_MARK_AS_SEEN';
export const UPDATE_ANNOUNCEMENT_MARK_ALL_AS_SEEN = 'UPDATE_ANNOUNCEMENT_MARK_ALL_AS_SEEN';
export const DELETE_ANNOUNCEMENTS = 'DELETE_ANNOUNCEMENTS';
export const REFRESH_ANNOUNCEMENTS = 'REFRESH_ANNOUNCEMENTS';
